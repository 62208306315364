import React from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";
import Numbers from "../components/numbers";
import IndividualPlan from "../components/individualPlan";

import { Link } from "gatsby";

const AboutFirm = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="O firmie"
      lang={lang}
      translationEN="/en/about-us/"
      translationDE="/de/uber-uns/"
    >
      <SmallBanner title="O firmie" lang={lang} />
      <section className="firm-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">
                Informacje <br />o firmie
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100 firm-info__left-padding">
                PalettenWerk istnieje od 1990 roku i obecnie jest jednym z
                największych producentów i eksporterów palet drewnianych w
                Polsce. Prawie 30 lat działalności pozwoliło firmie zyskać
                bogate doświadczenie w produkcji i dostawach palet dla niemal
                wszystkich branż i gałęzi przemysłu. Palety PalettenWerk
                stosowane są przez kilkaset firm z branży spożywczej,
                budowlanej, chemicznej, farmaceutycznej, browarniczej, metalowej
                oraz w innych gałęziach przemysłu.
              </p>
              <img
                className="firm-info__left-padding"
                src={require("../assets/img/firm-info1.jpg")}
                alt="Firm info"
              />
            </div>
          </div>
          <div className="row firm-info__center-row">
            <div className="col-12 col-lg-6 col-xl-4">
              <div
                className="center-row__image"
                style={{
                  backgroundImage: `url(${require("../assets/img/firm-info2.jpg")})`,
                }}
              ></div>
            </div>
            <div className="col-12 col-lg-6 col-xl-8">
              <p className="firm-info__left-padding">
                Każdego miesiąca zakłady firmy opuszcza 900 TIRów
                dostarczających palety do Klientów w kraju i za granicą.
                Standardy jakości i obsługi klienta w PalettenWerk pozwalają
                spełniać oczekiwania nawet najbardziej wymagających Klientów
                krajowych i zagranicznych. Wśród odbiorców PalettenWerk znajdują
                się największe koncerny i korporacje, a 60% produkcji jest
                eksportowana do ponad 15 krajów Europy.
              </p>
            </div>
          </div>
          <div className="row firm-info__last-row">
            <div className="col-12 col-lg-6 col-xl-8">
              <p>
                W celu zapewnienia jakości oraz optymalizacji produkcji i
                logistyki, firma PalettenWerk realizuje szereg działań. Składają
                się one na system zarządzania jakością, system zamawiania i
                organizacji dostaw, system zarządzania surowcami, system
                wspomagania przepływu materiałów oraz dzienny program nadzoru
                ubytków i strat.
              </p>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
              <img
                className="last-row__image"
                src={require("../assets/img/firm-info3.jpg")}
                alt="Firm info"
              />
            </div>
          </div>
        </div>
      </section>
      <Numbers lang={lang} />
      <section className="timeline" id="30-lat-na-rynku">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-20">30 lat na rynku</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100">
                Nasza historia rozpoczęła się w roku 1990. W ciągu 30 lat nasza
                firma rozwinęła się z małej firmy handlowej do jednego z
                największych producentów palet w Europie wprowadzającego na
                rynek ponad 7 milionów palet rocznie. Jest to możliwe dzięki
                ciągłej modernizacji i inwestycjom optymalizującym produkcję,
                zarządzanie i sprzedaż naszych produktów. Mimo osiągnięcia tak
                wysokiego pułapu, firma w dalszym ciągu zachowuje charakter
                firmy rodzinnej, łącząc tradycję z nowoczesnymi technikami
                zarządzania przedsiębiorstwem. Dzięki temu jesteśmy w stanie
                stworzyć przyjazne warunki pracy, w których dobrze czują się
                wszyscy zatrudnieni, a często są to osoby pracujące od początku
                działalności naszej firmy. Minione 30 lat jest dobrym punktem
                wyjścia do dalszego rozwoju i sprostaniu wyzwaniom dynamicznie
                zmieniającej się gospodarki.
              </p>
            </div>
          </div>
        </div>
        <div className="timeline__container">
          <div className="timeline__holder">
            <div className="timeline__item">
              <p className="item__date xxl color--main">1990</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline1.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                Nasza historia rozpoczęła się w roku 1990, kiedy zaczęliśmy
                realizować pierwsze dostawy palet na rynek niemiecki. Były to w
                dużej mierze palety zakupione od małych zakładów.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1992</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline2.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                W 1992 rozpoczęliśmy własną produkcję, w pierwszej lokalizacji
                naszej firmy – Bystrej Podhalańskiej. Produkowaliśmy na
                zakupionej za granicą, używanej linii automatycznej jedynie
                palety typu EURO.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1995</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline3.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                W 1995 zainstalowaliśmy jako jeden z pierwszych zakładów w
                Polsce dedykowaną linię do produkcji palet nietypowych,
                umożliwiającą kompleksowe zaspokojenie potrzeb klientów oraz
                optymalne wykorzystanie surowca.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1998</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline4.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                W 1998 roku nabyliśmy teren w Gniewięcinie, w województwie
                świętokrzyskim, gdzie powstała firma Palko sp. z o.o.
                produkująca palety EPAL.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2000</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline5.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                W roku 2000 dokonaliśmy zakupu terenu w Jordanowie i
                przenieśliśmy naszą firmę do nowej lokalizacji. Na nowym,
                znacznie większym terenie, powstała nowoczesna hala o
                powierzchni ponad 3000m2, kompleks suszarń oraz nowa linia do
                produkcji palet EPAL.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2000-2020</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline6.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                W latach 2000-2020 nastąpiły intensywne inwestycje w park
                maszynowy oraz zakryte przestrzenie magazynowe, a także
                nowoczesne suszarnie i piece. Nabyliśmy m.in. trzy automatyczne
                linie do produkcji palet marki Erba OMEV, dwie linie produkcyjne
                CAPE, dwie automatyczne linie do produkcji palet typu DHP.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2018</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline7.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                W 2018 roku uruchomiliśmy produkcję w naszym nowym zakładzie na
                Ukrainie, w Czerwonogradzie, skąd dostarczamy palety zarówno na
                Ukrainę jak i do sąsiednich krajów.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2020</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../assets/img/timeline8.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                Obecnie Grupa PalettenWerk dysponuje 30ha zadaszonej powierzchni
                magazynowej, 26 nowoczesnymi suszarniami z rekuperacją oraz 9 w
                pełni automatycznymi liniami produkcyjnymi
              </p>
            </div>
            <div className="timeline__end-element"></div>
          </div>
        </div>
      </section>
      <IndividualPlan
        title="Dlaczego my?"
        text="Gigantyczny potencjał produkcyjny i magazynowy oraz pełna obsługa logistyczna stawiają Nas na pozycji lidera branży produkcji palet w Polsce i Europie. Jesteśmy przygotowani w każdym zakresie do sprostania oczekiwaniom najbardziej wymagających klientów. Składa się na to nasze kilkudziesięcioletnie doświadczenie, zespół wykwalifikowanych ludzi, zaplecze techniczno-logistyczne oraz nieustanna chęć doskonalenia naszej pracy. Potwierdzeniem wysokiej jakości naszych produktów są posiadane przez nas liczne certyfikaty m.in. Licencja EPAL (na wszystkie typy palet), Licencja na produkcję palet CP (Chemical Pallets, wszystkie typy), Świadectwo ISPM15, Certyfikat Jakości ISO 9001:2015, Certyfikat PEFC, Upoważnienie AEO i inne.
          Stosujemy zasadę, że największą wartością jest Nasz Klient."
        lang={lang}
      />
      <section className="firm-info-ecology">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main">Ochrona środowiska</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100">
                Firma PalettenWerk realizuje działania zmierzające do
                racjonalnego i bezpiecznego oddziaływania na środowisko
                naturalne. Polityka ekologiczna PalattenWerk obejmuje efektywne
                i oszczędne gospodarowanie surowcami i energią oraz ograniczenie
                do minimum negatywnego wpływu procesów produkcyjnych na
                środowisko.
              </p>
            </div>
            <div className="col-12 offer-quality__btn-holder">
              <Link
                className="btn"
                to="/ochrona-srodowiska/"
                data-hover="Zobacz więcej"
              >
                Zobacz więcej
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutFirm;
