import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import t from "../locale";

const Numbers = ({ lang }) => {
	const [focus, setFocus] = useState(false);

	return (
		<section className="numbers">
			<div className="container container--big numbers__holder">
				<div className="holder__line"></div>
				<div className="holder__line"></div>
				<div className="holder__line"></div>
				<div className="holder__line"></div>
				<div className="holder__line"></div>
				<div className="row">
					<div className="col-12 col-md-6 col-lg-3 col-xl-2 offset-xl-2 numbers__item">
						<p className="item__number number color--white">
							<CountUp
								start={focus ? 0 : null}
								end={30}
								duration={10}
								redraw={true}
							>
								{({ countUpRef }) => (
									<VisibilitySensor
										onChange={(isVisible) => {
											if (isVisible) {
												setFocus(true);
											}
										}}
									>
										<span ref={countUpRef} />
									</VisibilitySensor>
								)}
							</CountUp>
						</p>
						<p
							className="item__text color--white"
							dangerouslySetInnerHTML={{
								__html: t(
									"Lat doświadczenia <br /> na rynku palet",
									lang
								),
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-3 col-xl-2 numbers__item">
						<p className="item__number number color--white">
							<CountUp
								start={focus ? 0 : null}
								end={17}
								duration={10}
								redraw={true}
							>
								{({ countUpRef }) => (
									<VisibilitySensor
										onChange={(isVisible) => {
											if (isVisible) {
												setFocus(true);
											}
										}}
									>
										<span ref={countUpRef} />
									</VisibilitySensor>
								)}
							</CountUp>
						</p>
						<p
							className="item__text color--white"
							dangerouslySetInnerHTML={{
								__html: t(
									"w krajach Europy <br /> wysyłki",
									lang
								),
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-3 col-xl-2 numbers__item">
						<p className="item__number number color--white">
							<CountUp
								start={focus ? 0 : null}
								end={300}
								duration={10}
								redraw={true}
							>
								{({ countUpRef }) => (
									<VisibilitySensor
										onChange={(isVisible) => {
											if (isVisible) {
												setFocus(true);
											}
										}}
									>
										<span ref={countUpRef} />
									</VisibilitySensor>
								)}
							</CountUp>
						</p>
						<p
							className="item__text color--white"
							dangerouslySetInnerHTML={{
								__html: t(
									"wykwalifikowanych <br /> pracowników",
									lang
								),
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-3 col-xl-2 numbers__item">
						<p className="item__number number color--white">
							<CountUp
								start={focus ? 0 : null}
								end={12}
								duration={10}
								redraw={true}
							>
								{({ countUpRef }) => (
									<VisibilitySensor
										onChange={(isVisible) => {
											if (isVisible) {
												setFocus(true);
											}
										}}
									>
										<span ref={countUpRef} />
									</VisibilitySensor>
								)}
							</CountUp>
						</p>
						<p
							className="item__text color--white"
							dangerouslySetInnerHTML={{
								__html: t(
									"ha powierzchni <br /> magazynu",
									lang
								),
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Numbers;
